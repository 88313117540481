
#download, #scaleSelectContainer, #openFile, #viewBookmark, #print {
  display: none!important;
}
.link-target {
  cursor: pointer;
}
.section__library .owl-carousel .owl-item {
  pointer-events: inherit;
}
.loading {
  position: relative;
  .section__library .owl-carousel .owl-item {
    pointer-events: none;
  }
}
